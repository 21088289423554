import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

import OrbContainer from '../../components/OrbContainer';
import useFormFactors from '../../helpers/useFormFactors';

import blob from '../../images/blobs/blob.png';
import AboutVector from '../../images/about/about-vector.svg';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 40px 0;
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
  }
`;

const Body = styled.div`
  padding: 24px;
  width: 100%;
  max-width: 782px;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 630px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 810px;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 0 24px 0;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    padding: 0 0 24px 16px;
  }
`;
const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 60px;
  font-weight: 600;
  line-height: 68px;
  margin: 0 0 16px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 80px;
    line-height: 88px;
    margin: 0 auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 114px;
    line-height: 114px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 24px 0 16px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 26px;
    max-width: 490px;
    margin: 24px auto 16px auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    line-height: 34px;
    margin: 34px 0 16px 0;
  }
`;

const Blob = styled.img`
  height: 905px;
  left: -232px;
  position: absolute;
  top: -408px;
  width: 849px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 480px;
    left: -48px;
    position: absolute;
    top: 124px;
    width: 480px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -80px;
    position: absolute;
    top: -351px;
    width: 800px;
  }
`;

const AboutHeaderVector = styled.img`
  width: 100%;
  max-width: 651px;
  margin: 68px 0 38px 0;
  z-index: 2;
  transform: scale(1.5);

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 100%;
    margin-top: 89px;
    transform: scale(1);
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 100%;
    margin-top: 89px;
    transform: scale(1);
  }
`;

const HeaderSection: FC = () => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();

  const formFactors = useFormFactors();

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <Section style={{ zIndex: 1 }}>
        <Body>
          <Title>About us</Title>
          <Heading>
            The future <br />
            of celebration is here
          </Heading>
          <Paragraph>
            Our mission is to make it easier than ever to share gratitude, love, and positivity. Our
            vision is a world where everyone feels celebrated for who they are.
          </Paragraph>
        </Body>
      </Section>

      <Section>
        <AboutHeaderVector alt='About Illustration' src={AboutVector} />
      </Section>
    </Layout>
  );
};

export default HeaderSection;
