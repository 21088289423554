import React, { FC } from 'react';
import styled from 'styled-components';
import Sohale from '../../images/about/sohale.png';
import Maxine from '../../images/about/maxine.png';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 44px 16px;
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 52px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 68px 0 40px 0;
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 100%;
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 100%;
    flex-direction: row;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 14px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 0 24px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    margin: 0 0 24px 0;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  max-width: 767px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    padding: 0 26px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    padding: 0 26px;
  }
`;

const LastParagraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  max-width: 767px;
  margin: 0 auto;
  padding: 128px 0 143px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    padding: 80px 26px 202px;
    text-align: center;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    padding: 0 26px;
    padding: 80px 26px 202px;
    text-align: center;
  }
`;

const FullSize = styled.div`
  display: none;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: flex;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    display: flex;
  }
`;

const MobileSize = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 21px;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 36px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
    margin-bottom: 0;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
    margin-bottom: 0;
  }
`;

const ProfileIMG = styled.img`
    max-width: 253px;
    width: 45%;

    @media ${(props) => props.theme.breakpoints.tabletP} {
        width: 298px;
      }
      @media ${(props) => props.theme.breakpoints.laptop} {
        width: 298px;
      }
`;

const FoundingStory: FC = () => {
  return (
    <Layout>
      <Heading>Our founding story</Heading>
      <FullSize>
        <Section>
          <Container>
            <ProfileIMG alt='Sohale Profile Image' src={Sohale} />
          </Container>
          <Container>
            <Paragraph>
              illume grew out of a project called, “lovenotes” at Stanford Business School. Sohale,
              co-founder and CEO of illume, felt that his classmates were apprehensive to open up to
              share positivity and gratitude. In an effort to open hearts, Sohale created a site
              where classmates could privately and anonymously share positivity and gratitude.
            </Paragraph>
          </Container>
          </Section>
          <Section>
          <Container>
            <Paragraph>
              The result was a burst of humanity that led him to working with co-founder and
              Stanford d-school classmate, Maxine Stern, to inspire friendships and spread lovenotes
              to other communities.
                <br/><br/>
              Today, illume carries lovenotes’s spirit as it creates a world where everyone is
              celebrated for who they are.
            </Paragraph>
          </Container>
          <Container>
            <ProfileIMG alt='Maxine Profile Image' src={Maxine} />
          </Container>
        </Section>
      </FullSize>


      <MobileSize>
        <Section>
          <Container>
            <ProfileIMG alt='Sohale Profile Image' src={Sohale} />
            <ProfileIMG alt='Maxine Profile Image' src={Maxine} />
          </Container>

          </Section>
          <Section>
          <Container>
            <Paragraph>
              illume grew out of a project called, “lovenotes” at Stanford Business School. Sohale,
              co-founder and CEO of illume, felt that his classmates were apprehensive to open up to
              share positivity and gratitude. In an effort to open hearts, Sohale created a site
              where classmates could privately and anonymously share positivity and gratitude.
            </Paragraph>
          </Container>
          <Container>
            <Paragraph>
              The result was a burst of humanity that led him to working with co-founder and
              Stanford d-school classmate, Maxine Stern, to inspire friendships and spread lovenotes
              to other communities.
                <br/><br/>
              Today, illume carries lovenotes’s spirit as it creates a world where everyone is
              celebrated for who they are.
            </Paragraph>
          </Container>
          <Container>
            
          </Container>
        </Section>
      </MobileSize>
      <LastParagraph>
            Our investors have backed some of the world’s most valuable companies including <br/> DoorDash, Dropbox, Allbirds, Gusto, and Airbnb.
      </LastParagraph>
    </Layout>
  );
};

export default FoundingStory;
