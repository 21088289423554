import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 40px 0;
  }
`;

const Section = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {

  }

  @media ${(props) => props.theme.breakpoints.laptop} {

  }
`;

const Body = styled.div`

  width: 100%;
  max-width: 976px;
  z-index: 2;
  display:flex;
  flex-direction:column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {

  }

  @media ${(props) => props.theme.breakpoints.laptop} {
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 14px 0;
  text-align: center;
  max-width: 910px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 0 15px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    margin: 0 0 15px 0;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  max-width: 767px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    padding: 0 26px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    padding: 0 26px;
  }
`;


const ForgingCommunity: FC = () => {

  return (
    <Layout>
      <Section style={{ zIndex: 1 }}>
        <Body>
          <Heading>
            Forging community and meaningful connection across communities
          </Heading>
          <Paragraph>
            illume is the celebration platform reimagining group cards and gifts for communities across the U.S. <br/>
            We exist to make every celebration easier than ever, sourcing inclusive and impactful gifts so that communities and small-businesses alike flourish and thrive.
          </Paragraph>
        </Body>
      </Section>
    </Layout>
  );
};

export default ForgingCommunity;
