import React, { FC } from 'react';
import styled from 'styled-components';

import about01 from '../../images/about/about01.svg';
import about02 from '../../images/about/about02.svg';
import about03 from '../../images/about/about03.svg';
import about04 from '../../images/about/about04.svg';
import about05 from '../../images/about/about05.svg';
import about06 from '../../images/about/about06.svg';
import about07 from '../../images/about/about07.svg';
import about08 from '../../images/about/about08.svg';
import about09 from '../../images/about/about09.svg';
import about10 from '../../images/about/about10.svg';
import about11 from '../../images/about/about11.svg';
import about12 from '../../images/about/about12.svg';
import about13 from '../../images/about/about13.svg';
import about14 from '../../images/about/about14.svg';
import about15 from '../../images/about/about15.svg';
import about16 from '../../images/about/about16.svg';
import about17 from '../../images/about/about17.svg';
import about18 from '../../images/about/about18.svg';
import about19 from '../../images/about/about19.svg';
import about20 from '../../images/about/about20.svg';
import about21 from '../../images/about/about21.svg';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px 56px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 40px 78px;
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
  }
`;

const Body = styled.div`
  max-width: 1224px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
  }
`;

const IconRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    div {
      padding: 10px;
      width: calc(100% / 3);
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    div {
      padding: 15px 10px;
      width: calc(100% / 7);
    }
  }
`;

const IconWrap = styled.img`
  width: 94px;
  margin: 16px 0;
`;

const IconWrapper: FC = () => {
  return (
    <Layout>
      <Section style={{ zIndex: 1 }}>
        <Body>
          <IconRow>
            <div>
              <IconWrap alt='Travelers' src={about01} />
            </div>
            <div>
              <IconWrap alt='Tesla' src={about02} />
            </div>
            <div>
              <IconWrap alt='Apple' src={about03} />
            </div>
            <div>
              <IconWrap alt='Disney' src={about04} />
            </div>
            <div>
              <IconWrap alt='Amazon' src={about05} />
            </div>
            <div>
              <IconWrap alt='Nasa' src={about06} />
            </div>
            <div>
              <IconWrap alt='Pinterest' src={about07} />
            </div>
            <div>
              <IconWrap alt='Harvard University' src={about08} />
            </div>
            <div>
              <IconWrap alt='Stanford University' src={about09} />
            </div>
            <div>
              <IconWrap alt='SAP' src={about10} />
            </div>
            <div>
              <IconWrap alt='Liberty Mutual' src={about11} />
            </div>
            <div>
              <IconWrap alt='Carvana' src={about12} />
            </div>
            <div>
              <IconWrap alt='Opendoor' src={about13} />
            </div>
            <div>
              <IconWrap alt='Nordstrom' src={about14} />
            </div>
            <div>
              <IconWrap alt='US Dept of Health and Human Services' src={about15} />
            </div>
            <div>
              <IconWrap alt='US Dept of State' src={about16} />
            </div>
            <div>
              <IconWrap alt='Lowes' src={about17} />
            </div>
            <div>
              <IconWrap alt='Whole Foods' src={about18} />
            </div>
            <div>
              <IconWrap alt='Shopify' src={about19} />
            </div>
            <div>
              <IconWrap alt='National Institutes of Health' src={about20} />
            </div>
            <div>
              <IconWrap alt='American Red Cross' src={about21} />
            </div>
          </IconRow>
        </Body>
      </Section>
    </Layout>
  );
};

export default IconWrapper;
