import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../components/MetaTags';
import { theme } from '../styles/theme';
import Background from '../components/Background';
import Header from '../components/Header2';
import HeaderSection from '../sections/about-us/HeaderSection';
import ForgingCommunity from '../sections/about-us/ForgingCommunity';
import IconWrapper from '../sections/about-us/IconWrapper';
import FoundingStory from '../sections/about-us/FoundingStory';

import data from '../sections/occasion/data';
import Footer from '../sections/common/Footer';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const AboutUs: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='About us | illume'
        description={
          'illume makes it easy to celebrate others. We offer ecards, group cards, group gifts and more for any occasion. Let’s make someone’s day, together.'
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection />
        <ForgingCommunity />
        <IconWrapper />
        <FoundingStory />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default AboutUs;
